<template>
  <van-popup
    v-model="incomingShow"
    round
    position="bottom"
    :safe-area-inset-bottom="true"
    :close-on-click-overlay="false"
    :style="{ height: '90%' }"
  >
    <div class="show-con">
      <p class="pup-title">
        吉用钱包将根据您的资质，为您匹配专业的贷款咨询服务公司（包含：
        <small>{{ companys }}</small>
        ）
      </p>
      <div class="pup-protocol u-text-center">
        <!-- 默认机构 -->
        <div
          v-for="(item, index) in defaultAgreements"
          :key="index + 'default'"
          class="pr"
          :class="item.flag ? '' : 'expand-height'"
        >
          <h4>{{ item.contractName }}</h4>
          <div class="protocol-content" v-html="item.contractContent"></div>
          <van-cell
            v-if="item.flag"
            class="pa islink"
            title="完整协议"
            is-link
            :border="false"
            :clickable="true"
            @click="
              () => {
                item.flag = false;
              }
            "
          />
        </div>
        <!-- 三方机构 -->
        <p
          v-if="thirdAgreementProductName && thirdAgreementCompanyName"
          style="font-size: 17px; margin-bottom: 10px; font-weight: 700"
        >
          {{ thirdAgreementCompanyName }}--{{ thirdAgreementProductName }}
        </p>
        <div
          v-for="(item, index) in thirdAgreements"
          :key="index + 'third'"
          class="pr"
          :class="item.flag ? '' : 'expand-height'"
        >
          <h4>《{{ item.contractName }}》</h4>
          <div class="protocol-content" v-html="item.contractContent"></div>
          <van-cell
            v-if="item.flag"
            class="pa islink"
            title="完整协议"
            is-link
            :border="false"
            :clickable="true"
            @click="
              () => {
                item.flag = false;
              }
            "
          />
        </div>
      </div>
    </div>
    <div class="go-on pa u-text-center">
      <p @click="handleGoOn" :class="source === 1 ? 'pbut_hs' : 'pbut_ls'">
        我已知晓
      </p>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "Agreement",
  props: {
    incomingShow: {
      type: Boolean,
      default: false,
    },
    sendData: {
      type: Object,
    },
    form: {
      type: Object,
    },
    applyTime: {
      type: String,
    },
    companys: {
      // 公司名称集合
      type: String,
    },
    companyAlias: {
      // 公司别名
      type: String,
    },
    productId: {
      // 产品id
    },
    applyId: {
      // 进件申请ID
    },
    source: {
      // 页面来源
      default: 1,
    },
  },
  data() {
    return {
      thirdAgreementProductName: null, //第三方产品名称
      thirdAgreementCompanyName: null, //第三方公司名称
      //三方机构
      thirdAgreements: [],
      //默认机构
      defaultAgreements: [],
    };
  },
  watch: {
    sendData(val) {
      //开始组装数据
      this.institutionHandle(val?.agreements);
    },
  },
  computed: {},
  methods: {
    institutionHandle(data) {
      if (data?.defaultAgreements) {
        this.defaultInstitutionHandle(data.defaultAgreements);
      }
      if (data?.thirdAgreement)
        this.thirdInstitutionHandle(data.thirdAgreement);
    },
    //默认机构数据处理
    defaultInstitutionHandle(defaultData) {
      if (!defaultData) return;
      this.defaultAgreements = [];
      defaultData.forEach((item) => {
        const newItem = { ...item }; // 浅拷贝item，避免直接修改原数组
        newItem.flag = true;
        this.defaultAgreements.push(newItem);
      });
    },
    //第三方机构数据处理
    thirdInstitutionHandle(thirdData) {
      if (!thirdData) return;
      this.thirdAgreements = [];
      this.thirdAgreementProductName = thirdData.productName;
      this.thirdAgreementCompanyName = thirdData.companyName;
      thirdData.agreements.forEach((item) => {
        const newItem = { ...item }; // 浅拷贝item，避免直接修改原数组
        newItem.flag = true;
        this.thirdAgreements.push(newItem);
      });
    },

    handleGoOn() {
      this.$emit("handleGoOn");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 0.1rem;

  .container-top {
    padding: 0.4rem 0.3rem 0;

    > div {
      padding: 0.3rem;
      background: #f6f8ff;
      font-size: 0.24rem;
      border-radius: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #868e9e;

      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.16rem;
      }

      .container-top-title {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: normal;
        color: #111c31;
        margin-bottom: 0.02rem;
      }

      .container-top-amount {
        font-size: 0.4rem;
        font-family: Helvetica, Helvetica-Helvetica;
        font-weight: normal;
        color: #111c31;
      }
    }
  }

  .register-form {
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.4rem 0.3rem 0.3rem;
  }

  .register-form-item {
    margin-bottom: 0.15rem;
    border-bottom: 0.02rem solid #f1f2f6;
    padding: 0.02rem 0;

    &.mb0 {
      margin-bottom: 0;
    }

    .inp-elem {
      padding: 0.2rem 0;
    }

    > h4 {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #111c31;
    }

    /deep/ {
      .van-field__label {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #111c31;
      }

      .van-field__control {
        color: #868e9e;
        font-size: 0.28rem;
      }

      .van-field__button {
        color: #fb6b62;
      }
    }

    small {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #868e9e;
      display: block;
      padding-bottom: 0.1rem;
    }

    .citys-tips {
      margin: 0.1rem 0 0.2rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #868e9e;
    }
  }

  .border-none {
    border: none;
  }

  .van-hairline--top-bottom::after {
    display: none;
  }

  .tags {
    font-size: 0.28rem;
    color: #868e9e;
  }

  .van-col {
    > div {
      height: 0.6rem;
      width: 100%;
      margin-top: 0.1rem;
      background: #ffff;
      border: 0.02rem solid #f1f2f6;
      border-radius: 0.12rem;
    }

    > div.mt0 {
      margin-top: 0;
    }
  }

  .tags .active {
    > div {
      background: rgba(53, 99, 250, 0.1);
      border-color: #fb6b62;
      color: #fb6b62;
    }
  }

  .btn-con {
    background: #fff;
    padding: 0 0.3rem;
    position: sticky;
    bottom: 0;
  }

  .btn {
    width: 100%;
    background: #fb6b62;
    border-radius: 0.48rem;
    border: 0;
    font-family: PingFangSC, PingFangSC-Medium;
    font-size: 0.32rem;
    font-weight: 500;
    color: #fff;
    height: 0.96rem;
    line-height: 0.96rem;
    vertical-align: bottom;
    margin: 0.24rem 0 0.3rem;
  }

  .btn-tips {
    left: 47%;
    bottom: 70%;
    height: 0.44rem;
    line-height: 0.44rem;
    padding: 0 0.18rem;
    background: #fb6b62;
    border-radius: 0.22rem 0.22rem 0.22rem 0;
    font-size: 0.22rem;
    font-family: PingFangSC, PingFangSC-Regular;
    color: #ffffff;
  }

  .protocols {
    padding: 0 0.3rem;
    font-size: 0.24rem;

    /deep/ .van-checkbox__icon {
      margin-top: 0.05rem;
    }
  }

  .protocols-title {
    color: #fb6b62;
  }

  .tel-con {
    margin-top: 0.36rem;
    font-size: 0.22rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: normal;
    color: #868e9e;
    > a {
      color: #fb6b62;
    }
  }

  .bottom {
    margin: 0.24rem auto;
    font-family: PingFangSC, PingFangSC-Regular;
    font-size: 0.22rem;
    text-align: center;
    color: #c3c2c6;
    line-height: 0.36rem;
    white-space: pre-line;
  }

  .show-con {
    padding: 0 0.4rem;
    height: 100%;
    overflow: hidden;
  }

  .pup-title {
    margin: 0.4rem 0 0.24rem;
    font-size: 0.24rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #868e9e;
    text-align: justify;
  }

  .pup-protocol {
    height: calc(100% - 2.98rem);
    overflow: auto;

    > div {
      height: 45%;
      background: #ebf3fd;
      border-radius: 0.12rem;
      padding: 0.26rem;
      margin-bottom: 0.2rem;
      overflow: hidden;
      transition: height 1s linear;
    }

    > div.expand-height {
      height: auto;
    }

    h4 {
      margin-bottom: 0.2rem;
      font-size: 0.3rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #3c3e41;
    }

    .islink {
      padding: 0.05rem;
      right: 0;
      bottom: 0;
      width: 1.7rem;
      box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
    }
  }

  .go-on {
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.6rem;
    background: #ffffff;
    box-shadow: 0px -0.04rem 0.2rem 0 rgba(0, 0, 0, 0.13);
    padding: 0.16rem 0.4rem 0.48rem;
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #ffffff;
  }
  .go-on .pbut_ls {
    line-height: 0.96rem;
    background: #3563fa;
    border-radius: 0.48rem;
  }

  .go-on .pbut_hs {
    line-height: 0.96rem;
    background: #fb6b62;
    border-radius: 0.48rem;
  }

  .show-protocol-con {
    height: 100%;
    padding: 0.4rem 0.4rem 0;

    > h4 {
      margin-bottom: 0.2rem;
      font-size: 0.3rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #3c3e41;
    }
  }

  .show-protocol-info {
    height: calc(100% - 1.45rem);
    overflow: auto;
  }

  .show-protocol-btn {
    left: 0;
    right: 0;
    border-top: 0.02rem solid #e3e3e3;
    font-size: 0.3rem;
    height: 0.8rem;
    line-height: 0.8rem;
    margin: 0 -0.4rem;
    font-family: PingFangSC, PingFangSC-Regular;
  }

  .picker-title {
    font-size: 0.32rem;

    p.pa {
      left: 0;
      top: 0.88rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #f44138;
      z-index: 2;
      padding: 0 0.25rem;
      text-align: left;
      background: #fff;
    }
  }
}
</style>
