
export const applyCommon = {
    data() {
        return {
            regTest: {
                // 正则校验
                regIdcard:
                  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, // 身份证号码正则校验
              },            
            // 表单枚举集合
            formItemData: {
                credit: [
                  // 芝麻分集合
                  { dictValue: "700以上", dictLabel: "700以上", active: false },
                  { dictValue: "650-700", dictLabel: "650-700", active: false },
                  { dictValue: "600-650", dictLabel: "600-650", active: false },
                ],
                antCreditPay: [
                  // 蚂蚁花呗集合
                  { dictValue: 0, dictLabel: "无", active: false },
                  { dictValue: 1, dictLabel: "有", active: false },
                ],
                jdIous: [
                  // 京东白条集合
                  { dictValue: 0, dictLabel: "无", active: false },
                  { dictValue: 1, dictLabel: "有", active: false },
                ],
                creditType: [
                  // 个人资产集合
                  { dictValue: 1, dictLabel: "有房" },
                  { dictValue: 3, dictLabel: "有公积金" },
                  { dictValue: 6, dictLabel: "企业主" },
                  { dictValue: 2, dictLabel: "有车" },
                  { dictValue: 4, dictLabel: "有社保" },
                  { dictValue: 5, dictLabel: "有商业保险" },
                ],
              },            
        }
    },
    methods: {
        // 选择芝麻分
        handleChooseCreditCredit(index, isComputeBar) {
            // 有选项被选中后计算顶部进度(如果芝麻分某一项被选中进度加5%)
            let arr = this.formItemData.credit.filter((item) => {
                return item.active === true;
            });
            this.formItemData.credit.map((item) => {
                item.active = false;
            });
            this.formItemData.credit[index].active = true;
            this.form.credit = this.formItemData.credit[index].dictValue;
            this.$forceUpdate();

            if (isComputeBar) {
                // 如果不是第一次选中则先减去之前增加的百分比，在增加对应选项的百分比
                if (arr.length) {
                    if (arr[0].dictLabel === "600以下") {
                        this.stepLineMarginL = this.stepLineMarginL - 3;
                        this.sesameFormula();
                    } else if (arr[0].dictLabel === "600-650") {
                        this.stepLineMarginL = this.stepLineMarginL - 5;
                        this.sesameFormula();
                    } else if (arr[0].dictLabel === "650-700") {
                        this.stepLineMarginL = this.stepLineMarginL - 10;
                        this.sesameFormula();
                    } else if (arr[0].dictLabel === "700以上") {
                        this.stepLineMarginL = this.stepLineMarginL - 15;
                        this.sesameFormula();
                    } else {
                        this.stepLineMarginL = this.stepLineMarginL - 0;
                        this.sesameFormula();
                    }
                } else {
                    // 如果是第一次选中进度增加对应的百分比
                    this.sesameFormula();
                }                
            }

            this.$refs.container.scrollTo({
                top: this.$refs.credit.offsetTop,
                behavior: "smooth",
            });
        },


        // 芝麻分判断公式
        sesameFormula() {
            if (this.form.credit === "600以下") {
                this.stepLineMarginL = this.stepLineMarginL + 3;
            } else if (this.form.credit === "600-650") {
                this.stepLineMarginL = this.stepLineMarginL + 5;
            } else if (this.form.credit === "650-700") {
                this.stepLineMarginL = this.stepLineMarginL + 10;
            } else if (this.form.credit === "700以上") {
                this.stepLineMarginL = this.stepLineMarginL + 15;
            } else {
                this.stepLineMarginL = this.stepLineMarginL + 0;
            }
        },

        // 选择信用信息（多选）
        handleSelectCreditInfo(index, isComputeBar) {
            let num = index === 0 ? 10 : index === 1 ? 6 : 4;
            // 取消选中
            if (this.formItemData.creditType[index].active) {
                let arr = this.formItemData.creditType.filter((item) => item.active);
                if (arr.length > 1) {
                    this.formItemData.creditType[index].active = false;
                    if (isComputeBar) {
                        this.stepLineMarginL = this.stepLineMarginL - num;
                    }
                } else {
                    this.$toast.fail("至少保留一项");
                }
            } else {
                // 选中
                this.formItemData.creditType[index].active = true;
                if (isComputeBar) {
                    this.stepLineMarginL = this.stepLineMarginL + num;
                }
            }
            
            this.$forceUpdate();
        },

        // 选择蚂蚁花呗
        handleChooseCreditAntCreditPay(index, isComputeBar) {

            // 有选项被选中后计算顶部进度(如果蚂蚁花呗某一项被选中-无进度加0% / 有进度加2%)
            let arr = this.formItemData.antCreditPay.filter((item) => {
                return item.active === true;
            });

            this.formItemData.antCreditPay.map((item) => {
                item.active = false;
            });
            this.formItemData.antCreditPay[index].active = true;
            this.form.antCreditPay = this.formItemData.antCreditPay[index].dictValue;
            this.$forceUpdate();

            if(isComputeBar) {
                // 如果不是第一次选中则先减去之前增加的百分比，在增加对应选项的百分比
                if (arr.length) {
                    if (arr[0].dictLabel === "有") {
                        this.stepLineMarginL = this.stepLineMarginL - 1;
                        if (this.form.antCreditPay === 0) {
                            this.stepLineMarginL = this.stepLineMarginL + 0;
                        } else {
                            this.stepLineMarginL = this.stepLineMarginL + 1;
                        }
                    } else {
                        this.stepLineMarginL = this.stepLineMarginL - 0;
                        if (this.form.antCreditPay === 0) {
                            this.stepLineMarginL = this.stepLineMarginL + 0;
                        } else {
                            this.stepLineMarginL = this.stepLineMarginL + 1;
                        }
                    }
                } else {
                    // 如果是第一次选中进度增加对应的百分比
                    if (this.form.antCreditPay === 1) {
                        this.stepLineMarginL = this.stepLineMarginL + 1;
                    } else {
                        this.stepLineMarginL = this.stepLineMarginL + 0;
                    }
                }
            }
        },

        // 选择京东白条
        handleChooseCreditJdIous(index, isComputeBar) {
            // 有选项被选中后计算顶部进度(如果京东白条某一项被选中-无进度加0% / 有进度加5%)
            let arr = this.formItemData.jdIous.filter((item) => {
                return item.active === true;
            });

            this.formItemData.jdIous.map((item) => {
                item.active = false;
            });
            this.formItemData.jdIous[index].active = true;
            this.form.jdIous = this.formItemData.jdIous[index].dictValue;
            this.$forceUpdate();

            if (isComputeBar) {
                // 如果不是第一次选中则先减去之前增加的百分比，在增加对应选项的百分比
                if (arr.length) {
                    if (arr[0].dictLabel === "有") {
                        this.stepLineMarginL = this.stepLineMarginL - 1;
                        if (this.form.jdIous === 0) {
                            this.stepLineMarginL = this.stepLineMarginL + 0;
                        } else {
                            this.stepLineMarginL = this.stepLineMarginL + 1;
                        }
                    } else {
                        this.stepLineMarginL = this.stepLineMarginL - 0;
                        if (this.form.jdIous === 0) {
                            this.stepLineMarginL = this.stepLineMarginL + 0;
                        } else {
                            this.stepLineMarginL = this.stepLineMarginL + 1;
                        }
                    }
                } else {
                    // 如果是第一次选中进度增加对应的百分比
                    if (this.form.jdIous === 1) {
                        this.stepLineMarginL = this.stepLineMarginL + 1;
                    } else {
                        this.stepLineMarginL = this.stepLineMarginL + 0;
                    }
                }
            }
        },  
        
        // 给浏览器的访问记录添加一条此页面的访问记录
        addHistory() {
            window.history.pushState(null, null, document.URL);
            window.addEventListener("popstate", this.openReturnPop, false);
        },

        // 打开回退弹窗
        openReturnPop() {
            this.returnShow = true;
            var equipmentSystem = this.mixinClientBrowser();
            if (equipmentSystem === "android") {
                try {
                //安卓直接使用popstate监听返回键无效，需要加这行代码
                window.tbs_bridge.nativeExec("network", "type", 0, null);
                } catch (e) {
                console.log(e);
                }
            }
        },
        
        // 判断是ios 还是安卓
        mixinClientBrowser() {
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                //ios
                return "ios";
            } else if (/(Android)/i.test(navigator.userAgent)) {
                //android
                return "android";
            }
        }                
    }
}